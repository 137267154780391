<template>
  <div class="editName">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">修改昵称</div>
      <div class="rightBtn" @click="modifyName">保存</div>
    </div>
    <div class="main">
      <div class="title">{{ currName }}</div>
      <van-field
        class="newInput"
        v-model="nameVal"
        :border="false"
        placeholder="输入新的昵称"
      >
        <template #button v-if="nameVal">
          <div class="clearBtn" @click="clearBtn">
            <svg-icon class="inputClose" iconClass="blueClose" />
          </div>
        </template>
      </van-field>
      <div class="tip">昵称30天内可修改4次</div>
    </div>
  </div>
</template>

<script>
import { Field, Toast } from "vant";
import { modifyUserInfo } from "@/api/user";
export default {
  components: {
    [Field.name]: Field,
  },
  data() {
    return {
      nameVal: "",
      currName: "",
    };
  },
  created() {
    this.currName = decodeURI(this.$route.query.n) || "";
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    clearBtn() {
      this.nameVal = "";
    },
    async modifyName() {
      let req = {
        name: this.nameVal,
      };
      if (!this.nameVal) {
        Toast("请输入新的昵称");
      }
      let ret = await this.$Api(modifyUserInfo, req);
      if (ret && ret.code == 200) {
        this.$router.go(-1);
        Toast("修改成功");
      } else {
        Toast(ret.tip || "编辑失败，请稍后再试");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editName {
  height: 100%;
  color: #000;
  background: #f5f5f5;
  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    // border-bottom: 1px solid #2b274a;

    .navArrow {
      width: 17px;
      height: 17px;
      margin-right: 17px;
    }

    .navTitle {
      font-size: 18px;
    }

    .rightBtn {
      width: 34px;
      font-size: 14px;
      color: #666;
      // width: 17px;
      // height: 17px;
    }
  }
  .main {
    height: calc(100% - 44px);
    padding: 0 12px;
    box-sizing: border-box;
    overflow-y: auto;
    .title {
      font-size: 24px;
      margin: 36px 0;
    }
    .newInput {
      padding: 0 0 12px 0;
      box-sizing: border-box;
      margin-bottom: 18px;
      background: #00000000;
      border-bottom: 1px solid #e6e6e6;
      ::placeholder {
        font-size: 18px;
        color: #a4a2b0;
      }
      /deep/ .van-field__control {
        font-size: 18px;
        color: #000;
      }
      .clearBtn {
        margin-right: 12px;
        width: 22px;
        height: 22px;
        .inputClose {
          width: 100%;
          height: 100%;
        }
      }
    }
    .tip {
      color: #77748a;
      font-size: 12px;
    }
  }
}
</style>
